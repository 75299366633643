import React from 'react';
import './headerHr.scss'

const HeaderHr = ({children}) => {
  return (
    <React.Fragment>
      {children}
      <hr className="header-hr"/>
    </React.Fragment>
  );
};

export default HeaderHr;