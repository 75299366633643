import React from 'react';
import PropTypes from 'prop-types';
import HeaderHr from '../components/headerHr';
import Container from '../components/container';
import GridRow from '../components/gridRow';
import htmlParse from 'html-react-parser';

const TextAreaOneColumn = ({title, column, bgColor}) => {
  return (
    <Container bgColor={bgColor}>
      {title && (
        <HeaderHr>
          <h2>{title}</h2>
        </HeaderHr>
      )}
        {column && (<p>{htmlParse(column)}</p>)}
    </Container>
  );
};

TextAreaOneColumn.propTypes = {
  title: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnThree: PropTypes.string,
  bgColor: PropTypes.string
};

export default TextAreaOneColumn;
