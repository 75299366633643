import React from 'react';
import PropTypes from 'prop-types';
import HeaderHr from '../components/headerHr';
import GridRow from '../components/gridRow';
import htmlParse from 'html-react-parser';
import Container from '../components/container';

const AboutUsSection = ({heading, image, textHeading, leftTextColumn, rightTextColumn}) => {
  return (
    <React.Fragment>
      <Container>
        {heading && (
          <HeaderHr>
            <h1>{heading}</h1>
          </HeaderHr>
        )}
        <img src={image} alt='#' style={{maxWidth: '860px', display: 'block', margin: '0 auto'}}/>
        <div style={{margin: '2rem 0 0'}}>
          <h3 style={{textAlign: 'center', fontSize: '1.67rem'}}>{textHeading}</h3>
          <GridRow rowItems={2}>
            <div>{leftTextColumn && htmlParse(leftTextColumn)}</div>
            <div>{rightTextColumn && htmlParse(rightTextColumn)}</div>
          </GridRow>
        </div>
      </Container>
    </React.Fragment>
  );
};

AboutUsSection.propTypes = {

};

export default AboutUsSection;
