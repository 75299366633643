import React from 'react';
import PropTypes from 'prop-types';
import HeaderHr from '../components/headerHr';
import GridRow from '../components/gridRow';
import htmlParse from 'html-react-parser';
import Container, { BACKGROUND_COLOR } from '../components/container';
import Card from "../components/card";
import Anchor, { ANCHOR_STYLE } from '../components/anchor';
import './servicesSC.scss';

const ServicesSC = ({title, services, backgroundColor, button}) => {
  return (
    <Container bgColor={backgroundColor}>
      {title && (
        <HeaderHr>
          <h2>{title}</h2>
        </HeaderHr>
      )}
      <GridRow rowItems={services.length}>
        {services.map(service => (
          <Card img={service.icon.publicURL} heading={service.title}>
            {htmlParse(service.content)}
          </Card>
        ))}
      </GridRow>
      {button && button.link && (
        <div className="sc-services__button">
          <Anchor
            text={button.text}
            to={button.link}
            styles={[ANCHOR_STYLE.BUTTON_WIDTH_FULLWIDTH, ANCHOR_STYLE.BUTTON_SIZE_LARGE_1]}
            isExternalLink={true}
          />
        </div>
      )}
    </Container>
  );
};

ServicesSC.propTypes = {

};

export default ServicesSC;
