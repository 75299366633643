import React from 'react';
import PropTypes from 'prop-types';
import './gridRow.scss';

const GridRow = ({children, rowItems}) => {
  return (
    <React.Fragment>
      <div className="grid-row">
        {React.Children.map(children, (child => (
          <div className={`grid-row__item grid-col-${rowItems}`}>
            {child}
          </div>
        )))}
      </div>
    </React.Fragment>
  );
};

GridRow.propTypes = {
  rowItems: PropTypes.number.isRequired
}

export default GridRow;