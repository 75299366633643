import React from 'react';
import PropTypes from 'prop-types';
import HeaderHr from '../components/headerHr';
import Container from '../components/container';
import GridRow from '../components/gridRow';
import htmlParse from 'html-react-parser';

const TextAreaThreeColumns = ({title, columnOne, columnTwo, columnThree, bgColor}) => {
  return (
    <Container bgColor={bgColor}>
      {title && (
        <HeaderHr>
          <h2>{title}</h2>
        </HeaderHr>
      )}
      <GridRow rowItems={3}>
        {columnOne && (<p>{htmlParse(columnOne)}</p>)}
        {columnTwo && (<p>{htmlParse(columnTwo)}</p>)}
        {columnThree && (<p>{htmlParse(columnThree)}</p>)}
      </GridRow>
    </Container>
  );
};

TextAreaThreeColumns.propTypes = {
  title: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnThree: PropTypes.string,
  bgColor: PropTypes.string
};

export default TextAreaThreeColumns;
