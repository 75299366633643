import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './heroSlider.scss';

const HeroSlider = ({slides}) => {
  return (
    <div>
      <Slider
        dots={true}
        infinite={true}
        autoplaySpeed={4000}
        speed={1000}
        autoplay={true}
        arrows={false}
        className="hero-slider__container"
      >
        {slides.map(({title, url}, index) => (
          <div key={url + index} className="hero-slider__element">
            <img className="hero-slider__img" src={url} alt="Slide Platzhalter Bild"/>
            <div className="hero-slider__overlay">
              <span className="hero-slider__text">{title}</span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

HeroSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }))
}

export default HeroSlider;
