import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './anchor.scss';

const Anchor = ({text, to, isExternalLink, styles}) => {
  const getClassNames = () => {
    let classNames = 'button ';
    styles.map(style => classNames += `${style} `);
    return classNames;
  }

  if (isExternalLink) {
    return (
      <a href={to} className={getClassNames()}>{text}</a>
    );
  } else {
    return (
      <Link to={to} className={getClassNames()}>{text}</Link>
    );
  }
};

export const ANCHOR_STYLE = Object.freeze({
  BUTTON_WIDTH_FULLWIDTH: 'button_width_fullwidth',
  BUTTON_SIZE_LARGE_1: 'button_size_large-1'
})


Anchor.propTypes = {
  text: PropTypes.string, //Text to be displayed
  to: PropTypes.string,
  isExternalLink: PropTypes.bool, //Whether its <Link> (internal link) or <a> (external link)
  styles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ANCHOR_STYLE)))
};

export default Anchor;
