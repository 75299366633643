import React from 'react';
import TextAreaThreeColumns from '../strapi-components/textAreaThreeColumns';
import ServicesSC from '../strapi-components/servicesSC';
import AboutUsSection from '../strapi-components/aboutUsSection';
import htmlParse from 'html-react-parser';
import HeroSlider from './heroSlider';
import TextAreaOneColumn from '../strapi-components/textAreaOneColumn';
import TextAreaTwoColumns from '../strapi-components/textAreaTwoColumn';

const STRAPI_COMPONENTS = Object.freeze({
  TEXTAREA_ONE_COLUMN: 'title_textarea_one_column',
  TEXTAREA_TWO_COLUMNS: 'title_textarea_two_columns',
  TEXTAREA_THREE_COLUMNS: 'title_textarea_three_columns',
  SERVICES: 'title_services',
  ABOUT_US: 'title_about_us',
  SLIDER: 'slides'
})

const StrapiComponentRender = ({content}) => {
  console.log('content: ', content);
  if (content[STRAPI_COMPONENTS.SLIDER]) {
    return <HeroSlider slides={content.slides.map(slide => ({title: slide.title, url: slide.image.publicURL}))}/>;
  }

  if (content[STRAPI_COMPONENTS.TEXTAREA_ONE_COLUMN] !== null &&
    content[STRAPI_COMPONENTS.TEXTAREA_ONE_COLUMN] !== undefined) {
    return <TextAreaOneColumn
      title={content.title_textarea_one_column}
      bgColor={content.backgroundcolor}
      column={content.richtext_content}
    />;
  }

  if (content[STRAPI_COMPONENTS.TEXTAREA_TWO_COLUMNS] !== null &&
    content[STRAPI_COMPONENTS.TEXTAREA_TWO_COLUMNS] !== undefined) {
    return <TextAreaTwoColumns
      title={content.title_textarea_two_columns}
      bgColor={content.backgroundcolor}
      columnOne={content.textcolumn_left}
      columnTwo={content.textcolumn_right}
    />;
  }

  if (content[STRAPI_COMPONENTS.TEXTAREA_THREE_COLUMNS] !== null &&
    content[STRAPI_COMPONENTS.TEXTAREA_THREE_COLUMNS] !== undefined) {
    return <TextAreaThreeColumns
      title={content.title_textarea_three_columns}
      bgColor={content.backgroundcolor}
      columnOne={content.textcolumn_left}
      columnTwo={content.textcolumn_middle}
      columnThree={content.textcolumn_right}
    />;
  }


  if (content[STRAPI_COMPONENTS.SERVICES] !== null &&
    content[STRAPI_COMPONENTS.SERVICES] !== undefined) {
    return <ServicesSC
      title={content.title_services}
      services={content.service}
      backgroundColor={content.backgroundcolor}
      button={content.button && {
        link: content.button.link,
        text: content.button.text
      }}
    />
  }

  if (content[STRAPI_COMPONENTS.ABOUT_US] !== null &&
    content[STRAPI_COMPONENTS.ABOUT_US] !== undefined) {
    console.log('inside')
    return <AboutUsSection
      heading={htmlParse(content.title_about_us)}
      image={content['Bild'].publicURL}
      textHeading={content['Textueberschrift']}
      leftTextColumn={content['left_textcolumn']}
      rightTextColumn={content['right_textcolumn']}
    />
  }

  return null;
};

StrapiComponentRender.propTypes = {
  // content: PropTypes.array
};

export default StrapiComponentRender;
