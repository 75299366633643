import React from 'react';
import PropTypes from 'prop-types';
import './card.scss';

const Card = ({children, img, altText, heading}) => {
  return (
    <div className="card">
      <img className="card__img" src={img} alt={altText}/>
      <span className="card__heading">{heading}</span>
      <div className="card__content">{children}</div>
    </div>
  );
};

Card.propTypes = {
  img: PropTypes.string,
  altText: PropTypes.string,
  heading: PropTypes.string
};

export default Card;